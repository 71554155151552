/*eslint-disable*/
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// @material-ui/icons
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Button from 'components/CustomButtons/Button.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js';

import { useLocation, useHistory } from 'react-router-dom';

import AccountPageStyle from './AccountPageStyle.js';

const useStyles = makeStyles(AccountPageStyle);

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AccountPage() {
  let query = useQuery();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isUserValidated, setIsUserValidated] = useState(false);
  const [name, setName] = useState(false);
  const [isDDS, setIsDDS] = useState(false);
  const [isDMD, setIsDMD] = useState(false);
  const [isDDSMS, setIsDDSMS] = useState(false);
  const [isDDSMD, setIsDDSMD] = useState(false);
  const [isMD, setIsMD] = useState(false);
  const [isDO, setIsDO] = useState(false);
  const [isPA, setIsPA] = useState(false);
  const [isNP, setIsNP] = useState(false);

  const downloadReferralForm = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api/document`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      },
      body: JSON.stringify({
        fullName: name,
        isDDS,
        isDMD,
        isDDSMS,
        isDDSMD,
        isMD,
        isDO,
        isPA,
        isNP
      })
    }).then(res => {
      if (res.ok) {
        return res.blob().then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = 'Referral Slips - Web.jpg';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        });
      } else {
        history.replace('/login');
      }
    });
  };

  React.useEffect(() => {
    let token = query.get('token') ?? localStorage.getItem('token');

    if (token) {
      setIsLoading(true);
      fetch(`${process.env.REACT_APP_API_URL}/api/auth`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify({ token })
      })
        .then(res => res.json())
        .then(data => {
          if (data.message === 'User has been validated.') {
            setIsUserValidated(true);
            setName(data?.user?.fullName);
            setIsDDS(data?.user?.isDDS ?? false);
            setIsDMD(data?.user?.isDMD ?? false);
            setIsDDSMS(data?.user?.isDDSMS ?? false);
            setIsDDSMD(data?.user?.isDDSMD ?? false);
            setIsMD(data?.user?.isMD ?? false);
            setIsDO(data?.user?.isDO ?? false);
            setIsPA(data?.user?.isPA ?? false);
            setIsNP(data?.user?.isNP ?? false);

            localStorage.setItem('token', token);
            history.replace('/account');
          } else {
            history.replace('/login');
          }
          setIsLoading(false);
        });
    } else {
      history.replace('/login');
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }, []);

  const classes = useStyles();

  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor='info' />}
        fixed
        color='info'
        // changeColorOnScroll={{
        //   height: 50,
        //   color: "info"
        // }}
      />
      <Parallax image={require('assets/img/osmdbg.jpg')} filter='dark' className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem md={8} sm={8} className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}>
              <h1 className={classes.title}>Referring Doctor</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer>
            {isLoading ? (
              <GridItem>
                <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h3>Verifying Account Details...</h3>
                  <CircularProgress />
                </div>
              </GridItem>
            ) : (
              <GridItem>
                {isUserValidated && (
                  <InfoArea
                    vertical
                    className={classes.info}
                    // title=''
                    description={
                      <form>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <div style={{ width: 400 }}>
                            <CustomInput
                              labelText='Full Name of Referring Practitioner'
                              id='full-name'
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                value: name,
                                onChange: e => {
                                  setName(e.target.value);
                                }
                              }}
                              require
                            />
                            <br />
                            <FormControl component='fieldset' className={classes.formControl}>
                              <FormLabel style={{ fontSize: 14 }} component='legend'>
                                Credentials
                              </FormLabel>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isDDS}
                                      onChange={(e, checked) => setIsDDS(checked)}
                                      name='DDS'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>DDS</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isDMD}
                                      onChange={(e, checked) => setIsDMD(checked)}
                                      name='DMD'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>DMD</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isDDSMS}
                                      onChange={(e, checked) => setIsDDSMS(checked)}
                                      name='DDS, MS'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>DDS, MS</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isDDSMD}
                                      onChange={(e, checked) => setIsDDSMD(checked)}
                                      name='DDS, MD'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>DDS, MD</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isMD}
                                      onChange={(e, checked) => setIsMD(checked)}
                                      name='MD'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>MD</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isDO}
                                      onChange={(e, checked) => setIsDO(checked)}
                                      name='DO'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>DO</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isPA}
                                      onChange={(e, checked) => setIsPA(checked)}
                                      name='PA'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>PA</span>}
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      style={{
                                        transform: 'scale(.8)'
                                      }}
                                      color='primary'
                                      checked={isNP}
                                      onChange={(e, checked) => setIsNP(checked)}
                                      name='NP'
                                    />
                                  }
                                  label={<span style={{ fontSize: 16 }}>NP</span>}
                                />
                              </FormGroup>
                            </FormControl>
                          </div>
                        </div>
                        <div className={classes.textCenter}>
                          <Button color='primary' round onClick={downloadReferralForm} disabled={!name}>
                            Download Referral Form
                          </Button>
                        </div>
                      </form>
                    }
                    icon={FileDownloadIcon}
                    iconColor='primary'
                  />
                )}
              </GridItem>
            )}
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
