/*eslint-disable*/
import React, { useEffect, useState } from 'react';
// okta
import { useOktaAuth } from '@okta/okta-react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Email from '@material-ui/icons/Email';
import Favorite from '@material-ui/icons/Favorite';
import Face from '@material-ui/icons/Face';
// core components
import Header from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Footer from 'components/Footer/Footer.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';

import loginPageStyle from './loginPageStyle.js';
import image from 'assets/img/osmdbg.jpg';

const useStyles = makeStyles(loginPageStyle);

export default function LoginPage({ history }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  // state
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [verifyEmailAddress, setVerifyEmailAddress] = useState(false);

  // functions
  function login() {
    setLoading(true);

    fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.message === 'Invalid credentials') {
          setInvalidCredentials(true);
        } else if (data.message === 'Verify email address') {
          setVerifyEmailAddress(true);
        }
        setLoading(false);
      });
  }

  const classes = useStyles();
  return (
    <>
      <div>
        <Header
          color='info'
          links={<HeaderLinks dropdownHoverColor='info' />}
          fixed
          // changeColorOnScroll={{
          //   height: 50,
          //   color: "info"
          // }}
        />
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form className={classes.form}>
                    <CardHeader color='primary' signup className={classes.cardHeader}>
                      <h4 className={classes.cardTitle}>Login</h4>
                    </CardHeader>
                    <CardBody signup>
                      {verifyEmailAddress ? (
                        <SnackbarContent
                          message={
                            <div>
                              Verify your email address by clicking the link sent to your email: <br />
                              <br />
                              From: <strong>no-reply@oralsurgerymd.com</strong>
                              <br />
                              Subject: <strong>"Dr Supancic Email Verification Link"</strong>
                            </div>
                          }
                          color='success'
                        />
                      ) : (
                        <>
                          <CustomInput
                            id='email'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              placeholder: 'Email...',
                              type: 'email',
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Email className={classes.inputIconsColor} />
                                </InputAdornment>
                              ),
                              value: email,
                              onChange: e => {
                                setEmail(e.target.value);
                                setInvalidCredentials(false);
                              }
                            }}
                          />
                          <CustomInput
                            id='pass'
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              placeholder: 'Password',
                              type: 'password',
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Icon className={classes.inputIconsColor}>lock_utline</Icon>
                                </InputAdornment>
                              ),
                              autoComplete: 'off',
                              value: password,
                              onChange: e => {
                                setPassword(e.target.value);
                                setInvalidCredentials(false);
                              },
                              onKeyDown: e => {
                                if (e.key === 'Enter') {
                                  login();
                                }
                              }
                            }}
                          />
                          {invalidCredentials && <SnackbarContent message='Invalid credentials' color='danger' />}
                        </>
                      )}
                    </CardBody>
                    {!verifyEmailAddress && (
                      <div className={classes.textCenter}>
                        <Button simple color='primary' size='lg' onClick={login} disabled={!email || !password || loading}>
                          Login
                        </Button>
                      </div>
                    )}
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
