/*eslint-disable*/
import React from 'react';
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js';
import Team from './Team.js';

import meetOurTeamPageStyle from './meetOurTeamPageStyle.js';

const useStyles = makeStyles(meetOurTeamPageStyle);

export default function MeetOurTeamPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Meet Our Team</title>

        <meta
          name='description'
          content='Get to know the dedicated team behind Oral & Facial Surgical Arts in Visalia, CA. Led by Dr. J. Steven Supancic, Jr., DDS, MD, we are committed to providing top-notch oral care.'
        />

        <link rel='canonical' href='https://oralsurgerymd.com/meet-our-team' />
      </Helmet>
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor='info' />}
          fixed
          color='info'
          // changeColorOnScroll={{
          //   height: 50,
          //   color: "info"
          // }}
        />
        <Parallax image={require('assets/img/osmdbg.jpg')} filter='dark' className={classes.parallax}>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem md={8} sm={8} className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}>
                <h1 className={classes.title} style={{ fontSize: 32 }}>
                  Meet Our Team
                </h1>
                <h4 style={{ fontSize: 16 }}>Meet the amazing team behind this office and find out more about how we work.</h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <Team />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
