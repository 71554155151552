/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
// okta
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';

import 'assets/scss/material-kit-pro-react.scss?v=1.8.0';

// pages for this product
// const HomePage = lazy(() => import("views/HomePage/HomePage.js"));
// const MeetOurTeamPage = lazy(() => import("views/MeetOurTeamPage/MeetOurTeamPage.js"));
// const ContactUsPage = lazy(() => import("views/ContactUsPage/ContactUsPage.js"));
// const WisdomTeethPage = lazy(() => import("views/WisdomTeethPage/WisdomTeethPage.js"));
// const LoginPage = lazy(() => import("views/LoginPage/LoginPage.js"));
// const ReferringDoctorsPage = lazy(() => import("views/ReferringDoctorsPage/ReferringDoctorsPage.js"));
import HomePage from 'views/HomePage/HomePage.js';
import MeetOurTeamPage from 'views/MeetOurTeamPage/MeetOurTeamPage.js';
import ContactUsPage from 'views/ContactUsPage/ContactUsPage.js';
import WisdomTeethPage from 'views/WisdomTeethPage/WisdomTeethPage.js';
import LoginPage from 'views/LoginPage/LoginPage.js';
import ReferringDoctorsPage from 'views/ReferringDoctorsPage/ReferringDoctorsPage.js';
import AccountPage from 'views/AccountPage/AccountPage.js';

const hist = createBrowserHistory({ forceRefresh: true });

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const OKTA_CALLBACK_PATH = '/login/callback';

const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`;
const HOST = window.location.host;
const REDIRECT_URI = `${process.env.NODE_ENV === 'production' ? 'https' : 'http'}://${HOST}${OKTA_CALLBACK_PATH}`;
const SCOPES = 'openid profile email';

const config = {
  issuer: ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: REDIRECT_URI,
  scopes: SCOPES.split(/\s+/)
};

const oktaAuth = new OktaAuth(config);

const App = () => {
  return (
    <Router history={hist}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={() => {
          hist.push('/login');
        }}
      >
        <Suspense fallback={null}>
          <Switch>
            <Route path='/wisdom-teeth' component={WisdomTeethPage} />
            <Route path='/meet-our-team' component={MeetOurTeamPage} />
            <Route path='/contact-us' component={ContactUsPage} />
            <Route path='/login' component={LoginPage} />
            <Route path='/login/callback' component={LoginCallback} />
            <SecureRoute path='/referring-doctors' component={ReferringDoctorsPage} />
            <Route path='/account' component={AccountPage} />
            <Route path='/' component={HomePage} />
          </Switch>
        </Suspense>
      </Security>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
