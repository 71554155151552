/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// okta
import { useOktaAuth } from '@okta/okta-react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
import PinDrop from '@material-ui/icons/PinDrop';
import Phone from '@material-ui/icons/Phone';
// core components
import DataGrid from 'components/DataGrid/DataGrid.jsx';
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Button from 'components/CustomButtons/Button.js';
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js';

import referringDoctorsPageStyle from './referringDoctorsPageStyle.js';

const useStyles = makeStyles(referringDoctorsPageStyle);

export default function ReferringDoctorsPage() {
  // okta auth
  const { authState, oktaAuth } = useOktaAuth();
  // state
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  // functions
  async function getUsers() {
    const accessToken = await oktaAuth.tokenManager.get('accessToken');
    await fetch(`${window.location.protocol}//${window.location.hostname}/api/api/users`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'accessToken': accessToken.value
      }
    })
      .then(response => response.json())
      .then(data => {
        setUsers(data);
        setLoadingUsers(false);
      });
  }

  // lifecycles
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor='info' />}
        fixed
        color='info'
        // changeColorOnScroll={{
        //   height: 50,
        //   color: "info"
        // }}
      />
      <Parallax image={require('assets/img/osmdbg.jpg')} filter='dark' className={classes.parallax}>
        <div className={classes.container}>
          <GridContainer justify='center'>
            <GridItem md={8} sm={8} className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}>
              <h1 className={classes.title}>Welcome, {authState?.idToken?.claims?.name}!</h1>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer spacing={2}>
            <GridItem md={12} sm={12}>
              <DataGrid
                columns={[{ title: 'Email', field: 'login' }, { title: 'First Name', field: 'firstName' }, { title: 'Last Name', field: 'lastName' }, { title: 'User Type', field: 'userType' }]}
                data={users}
                title='Users'
                isLoading={loadingUsers}
              />
            </GridItem>
            <GridItem md={12} sm={12}>
              <DataGrid
                columns={[{ title: 'First Name', field: 'firstName' }, { title: 'Last Name', field: 'lastName' }, { title: 'Status', field: 'status' }]}
                data={[]}
                title='Patients'
                isLoading={false}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
