import { primaryColor, warningColor, dangerColor, successColor, infoColor, roseColor, grayColor, title } from 'assets/jss/material-kit-pro-react.js';

const infoStyle = {
  infoArea: {
    // maxWidth: "360px",
    margin: '8px'
  },
  iconWrapper: {
    float: 'left',
    marginTop: '24px',
    marginRight: '10px'
  },
  primary: {
    color: primaryColor[0]
  },
  warning: {
    color: warningColor[0]
  },
  danger: {
    color: dangerColor[0]
  },
  success: {
    color: successColor[0]
  },
  info: {
    color: infoColor[0]
  },
  rose: {
    color: roseColor[0]
  },
  gray: {
    color: grayColor[0]
  },
  icon: {
    width: '2.25rem',
    height: '2.25rem',
    fontSize: '2.25rem'
  },
  descriptionWrapper: {
    // color: grayColor[0],
    overflow: 'hidden'
  },
  title: {
    ...title,
    marginTop: 10,
    marginBottom: 10,
    minHeight: 'unset',
    textAlign: 'center'
  },
  description: {
    // color: grayColor[0],
    'overflow': 'hidden',
    'marginTop': '0px',
    '& p': {
      // color: grayColor[0],
      fontSize: '16px'
    }
  },
  iconWrapperVertical: {
    float: 'none',
    textAlign: 'center'
  },
  iconVertical: {
    width: '61px',
    height: '61px'
  }
};

export default infoStyle;
