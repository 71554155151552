/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// nodejs library that concatenates classes
import classNames from 'classnames';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Favorite from '@material-ui/icons/Favorite';
import PinDrop from '@material-ui/icons/PinDrop';
import Phone from '@material-ui/icons/Phone';
// core components
import Header from 'components/Header/Header.js';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Parallax from 'components/Parallax/Parallax.js';
import Footer from 'components/Footer/Footer.js';
import InfoArea from 'components/InfoArea/InfoArea.js';
import Button from 'components/CustomButtons/Button.js';
// sections for this page
import HeaderLinks from 'components/Header/HeaderLinks.js';

import WisdomTeethPageStyle from './WisdomTeethPageStyle.js';

const useStyles = makeStyles(WisdomTeethPageStyle);

export default function WisdomTeethPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Wisdom Teeth Extraction in Visalia, CA</title>

        <meta
          name='description'
          content='Wisdom teeth extraction by Dr. J. Steven Supancic, Jr., DDS, MD in Visalia, CA. Ensure the health of your smile with expert removal procedures. Schedule your consultation today.'
        />

        <link rel='canonical' href='https://oralsurgerymd.com/wisdom-teeth' />
      </Helmet>
      <div>
        <Header
          links={<HeaderLinks dropdownHoverColor='info' />}
          fixed
          color='info'
          // changeColorOnScroll={{
          //   height: 50,
          //   color: "info"
          // }}
        />
        <Parallax image={require('assets/img/osmdbg.jpg')} filter='dark' className={classes.parallax}>
          <div className={classes.container}>
            <GridContainer justify='center'>
              <GridItem md={8} sm={8} className={classNames(classes.mlAuto, classes.mrAuto, classes.textCenter)}>
                <h1 className={classes.title} style={{ fontSize: 32 }}>
                  Wisdom Teeth Extraction
                </h1>
                <h4 style={{ fontSize: 16 }}>in Visalia, CA</h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={6} sm={12}>
                <InfoArea
                  className={classes.infoArea}
                  title='Wisdom Teeth Management'
                  description={
                    <div>
                      <p>
                        Are you experiencing discomfort or pain in your back molars? Do you have difficulty opening your mouth fully? These may be signs that it's time to have your wisdom teeth
                        removed.
                      </p>
                      <p>
                        Wisdom teeth, also known as third molars, are the last set of teeth to emerge in the mouth. They usually appear in the late teenage years or early twenties. However, not
                        everyone has enough space in their mouth for these teeth to come in properly. When this happens, the teeth can become impacted, meaning they are stuck and cannot emerge from
                        the gums. Impacted wisdom teeth can cause a range of problems, including pain, infection, and damage to surrounding teeth.
                      </p>
                      <p>
                        If you are experiencing any of these issues, it's important to have your wisdom teeth evaluated by an oral surgeon. Oral surgeons are dental professionals who have undergone
                        specialized training in surgical procedures, including wisdom teeth removal. They have the expertise and experience to safely and effectively remove your wisdom teeth,
                        minimizing the risk of complications and ensuring a smooth recovery.
                      </p>
                      <p>Don't let wisdom teeth problems go untreated. Contact us today and take the first step towards a healthier, more comfortable smile.</p>
                      <div style={{ textAlign: 'center' }}>
                        <Link to={'/contact-us'}>
                          <Button color='primary' round>
                            Contact Us to Schedule a Consultation
                          </Button>
                        </Link>
                      </div>
                    </div>
                  }
                />
              </GridItem>
              <GridItem md={6} sm={12}>
                <InfoArea
                  className={classes.infoArea}
                  title='What Are Wisdom Teeth?'
                  description={
                    <p>
                      Wisdom teeth are the last teeth to develop and appear in your mouth. They enter the mouth behind the upper and lower second, or 12-year, molars between the ages of 17 and 25, a
                      time of life that has been called the "Age of Wisdom."
                    </p>
                  }
                />
                <InfoArea
                  className={classes.infoArea}
                  title='What is an Impacted Tooth?'
                  description={
                    <p>
                      An impacted tooth is a tooth that is unable to emerge fully into the mouth because it is blocked by other teeth, bone, or soft tissue. This can cause problems with the
                      development of the tooth and can lead to pain, infection, and other dental issues. Impacted teeth are most commonly wisdom teeth, but any tooth can become impacted if it is
                      prevented from erupting into the mouth normally. If an impacted tooth is causing problems, it may need to be removed surgically.
                    </p>
                  }
                />
                <InfoArea
                  className={classes.infoArea}
                  title='Why Are Impacted Wisdom Teeth a Concern?'
                  description={
                    <span>
                      <p>An impacted tooth may be painful and can contribute to a variety of problems, including:</p>
                      <ul className={classes.infoAreaList}>
                        <li>Infection</li>
                        <li>Damage to neighboring teeth and roots</li>
                        <li>Tooth decay</li>
                        <li>Periodontal disease</li>
                        <li>Receding gums</li>
                        <li>Loosened teeth</li>
                        <li>Bone loss</li>
                        <li>Tooth loss</li>
                      </ul>
                      <p>More serious problems may occur if the sac surrounding the impacted tooth becomes filled with fluid and enlarges to form a cyst.</p>
                    </span>
                  }
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
