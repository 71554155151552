import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import teamStyle from './teamStyle.js';

import Barbara from 'assets/img/faces/Barbara.jpg';
import Lori from 'assets/img/faces/Lori.jpg';
import Miranda from 'assets/img/faces/Miranda.jpg';
import Norma from 'assets/img/faces/Norma.jpg';
import Felicia from 'assets/img/faces/Felicia.jpg';
import Kristen from 'assets/img/faces/Kristen.jpg';

const useStyles = makeStyles(teamStyle);

export default function Team() {
  const classes = useStyles();
  return (
    <div className={classes.team}>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a>
                <img src={Barbara} alt='Barbara' className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>BARBARA BULLER</h4>
              <h6 className={classes.textMuted}>RDA, OMSA</h6>
              <p className={classes.cardDescription}>
                Barbara Buller has been part of Dr. Supancic’s team for over 25 years. Currently, she is the office manager and dental implant coordinator. But she also assist in office surgeries, and
                surgeries performed at Kaweah Delta Hospital and Visalia Children’s Dental Surgery Center. Barbara has her OMSA certification and is BLS/ACLS certified. She is a graduate of Reedley
                College Dental Assisting Program and has been an RDA for over 35 years. She has served as an Adjunct Professor at Reedley College were she taught dental assisting to students striving
                to obtain their RDA. Barbara has worked in general dentistry as well as orthodontics. However, surgery has always been her passion! Barbara lives in Visalia, and has two adult sons.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a>
                <img src={Felicia} alt='Felicia Handley' className={classes.img} style={{ objectPosition: 'bottom 20px right 0px' }} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>FELICIA HANDLEY</h4>
              <h6 className={classes.textMuted}>RDA, OMSA</h6>
              <p className={classes.cardDescription}>
                Felicia is a returning member to Dr. Supancic’s team. She is one of our friendly faces you are likely to meet at the front desk. Felicia is also trained in the back office to assists
                with surgeries. She is a graduate of Galen College Dental Assisting Program, has her OMSA certification, and has been a dental assistant for 18 years and an RDA for 15 years. She is
                BLS certified. Felicia is married with 2 grown sons.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a>
                <img src={Kristen} alt='Kristen' className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>KRISTEN DUGAY</h4>
              <h6 className={classes.textMuted}>DA, OMSA</h6>
              <p className={classes.cardDescription}>
                Kristen DuGay has been part of Dr. Supancic’s team since August 2021. She obtained her Dental Assisting Certificate from Galen College, is a Dinuba High School graduate and has worked
                in general dentistry for over 15 years. She is BLS certified and currently in the process of furthering her education by completing her OMSA course. She enjoys assisting and working in
                the back office. Kristen has 3 children, a 13 year-old daughter, a 9 year-old son and a 5 year-old son.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a>
                <img src={Lori} alt='Lori' className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>LORI BELLEFEUILLE</h4>
              <h6 className={classes.textMuted}>RDA, OMSA</h6>
              <p className={classes.cardDescription}>
                Lori Bellefeuille has been part of Dr. Supancic’s team for over 24 years. She is our front office manager and insurance coordinator, although she does assist in surgeries at various
                times. Lori has her OMSA certification and is BLS/ACLS certified. She is a graduate of Reedley College Dental Assisting Program and been an RDA for well over 10 years. Lori was born in
                Visalia. She is married, and has a son and one grandchild.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a>
                <img src={Miranda} alt='Miranda' className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>MIRANDA LOGAN</h4>
              <p className={classes.cardDescription}>
                Miranda Logan joined Dr. Supancic’s team in 2016. She is BLS certified. Miranda assists in surgery and has front office responsibilities. She is a graduate of Mt. Whitney High School
                and currently attending COS to further her academic development.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        {/* <GridItem md={3} sm={3}>
          <Card profile plain>
            <CardAvatar profile plain>
              <a>
                <img src={Norma} alt='Norma' className={classes.img} />
              </a>
            </CardAvatar>
            <CardBody plain>
              <h4 className={classes.cardTitle}>NORMA DELEON</h4>
              <h6 className={classes.textMuted}>RDA</h6>
              <p className={classes.cardDescription}>
                Norma DeLeon is the newest member of the team. She joined the practice in February 2019. Norma assists in almost all surgery performed in the office. Norma is a 1996 graduate of
                Reedley College Dental Assisting Program. She has her RDA and is BLS certified. Norma has worked 10 years in endodontics, and has an 8 year experience in general dentistry. Norma's
                purpose and passion is to make your experience delightful and a pleasant one!
              </p>
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
  );
}
